<template>
<div>
    <el-menu :default-active="isActive" background-color="#EBD8D8" text-color="black" active-text-color="#ff0000"
        class="el-menu-vertical-demo" :collapse="isCollapse" style="position:fixed;height:100vh;">
        <div style="margin-bottom:24px;cursor:pointer">
            <i class="el-icon-s-fold" style="font-size:23px;float:right;color:black" @click="toggleSidebarFunc"
                v-if="!isCollapse"></i>
            <i class="el-icon-s-unfold" style="font-size:23px;color:black" @click="toggleSidebarFunc" v-else></i>
        </div>
        <items v-for="(data, index) in menuItems" :key="index" :label="data.label" :name="data.name" :icon="data.icon"
            :child="data.child" :isCollapse="isCollapse" />
    </el-menu>
</div>
</template>

<script>
import items from './newsidebaritems'
import {
    bus
} from '@/eventbus/eventbus'
export default {
    components: {
        items,
        bus
    },
    data() {
        return {
            screenLength: window.screen.width,
            isCollapse: false,
            isActive: this.$route.name,
            menuItems: [
                {
                    label: 'Dashboard',
                    name: 'dashboard',
                    icon: 'fa fa-gauge'
                },
                {
                    label: 'Ingram',
                    name: 'Ingramcustoms',
                    icon: 'fa fa-users',
                    child: [
                        {
                            label: 'Dashboard',
                            name: 'ingramDashboard',
                            icon: 'fa fa-gauge'
                        },
                        {
                            label: 'Customers',
                            name: 'ingramCustomer',
                            icon: 'fa fa-users'
                        },
                        {
                            label: 'Subscriptions',
                            name: 'ingramSubscription',
                            icon: 'fa fa-bag-shopping'
                        }
                    ]
                },
                {
                    label: 'Aispl',
                    name: 'Aisplcustoms',
                    icon: 'fa fa-users',
                    child: [
                        {
                            label: 'Dashboard',
                            name: 'aisplDashboard',
                            icon: 'fa fa-gauge',
                        },
                        {
                            label: 'Customers',
                            name: 'aisplCustomer',
                            icon: 'fa fa-users',
                        },
                        {
                            label: 'Subscriptions',
                            name: 'aisplSubscription',
                            icon: 'fa fa-bag-shopping',
                        },
                    ],
                },
                {
                    label: 'User Management',
                    name: 'userManagement',
                    icon: 'fa-solid fa-users-rectangle',
                    child: [
                        {
                            label: 'Users',
                            name: 'usersList',
                            icon: 'fa fa-user',
                        }
                    ]
                },
                {
                    label: 'Renewal',
                    name: 'allRenewals',
                    icon: 'fa-solid fa-list'
                },
                // {
                //     label: 'Renewals',
                //     name: 'allRenewals',
                //     icon: 'fa-solid fa-list',
                //     child:[
                //         {
                //            label: 'Renewals List',
                //             name: 'allRenewals',
                //             icon: 'fa-solid fa-list'
                //         },
                //         {
                //             label: 'Email Setting',
                //             name: 'renewalEmailSetting',
                //             icon: 'fa-solid fa-list',
                //         }
                //     ]
                // },
                {
                    label: 'Price Lists',
                    name: 'priceList',
                    icon: 'fa-solid fa-list',
                    child: [
                        {
                            label: 'Ingram',
                            name: 'priceList',
                            icon: 'fa-solid fa-list'
                        },
                        {
                            label: 'NCE',
                            name: 'ncePriceList',
                            icon: 'fa-solid fa-list'
                        },
                        {
                            label: 'Perpetual',
                            name: 'perpetualPriceList',
                            icon: 'fa-solid fa-list'
                        },
                        {
                            label: 'Software',
                            name: 'softwarePriceList',
                            icon: 'fa-solid fa-list'
                        },
                    ]
                },
                {
                    label: 'Orders',
                    name: 'orders',
                    icon: 'fa-solid fa-list'
                },
                {
                    label: 'Settings',
                    name: 'settings',
                    icon: 'fa-solid fa-list'
                },
                {
                  label: 'Support',
                  name: 'Support',
                  icon: 'fa-solid fa-list',
                  child: [
                      {
                          label: 'Ticket Create',
                          name: 'SupportTicketCreate',
                          icon: 'fa-solid fa-list'
                      },
                      {
                          label: 'Ticket List',
                          name: 'SupportTicketList',
                          icon: 'fa-solid fa-list'
                      },
                  ]
                },
                // {
                //     label: 'Customer Details',
                //     name: 'customerDetailsListss',
                //     icon: 'fa-solid fa-list',
                //     child:[
                //         {
                //            label: 'Microsoft',
                //             name: 'customerDetailsList',
                //             icon: 'fa-solid fa-list'
                //         },
                //         {
                //             label: 'Adobe',
                //             name: 'customerDetailsListAdobe',
                //             icon: 'fa-solid fa-list',
                //         }
                //     ]
                // },
            ]
        }
    },
    created() {
        if (this.screenLength < 800) {
            this.isCollapse = true
            bus.$emit('sidebarToggle', this.isCollapse)
        }
    },
    methods: {
        toggleSidebarFunc() {
            this.isCollapse = !this.isCollapse
            bus.$emit('sidebarToggle', this.isCollapse)
        }
    }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 210px;
    min-height: 400px;
}

a {
    text-decoration: none;
}
</style>
<style scoped>
.router-link-exact-active {
    color: red;
}

a {
    color: black;
}

.el-menu-item i {
    color: black;
    font-size: 16px;
}

.el-submenu__title i {
    color: black;
    font-size: 16px;
}
</style>
