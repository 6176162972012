const partnerId="a69a61a6-a339-4b25-8df2-82f67f905247"
const clientId = "af726645-ec20-4ad5-9839-5f76c4b72739"
const auths={
    auth: {
    'clientId':clientId,
    "authority":"https://login.microsoftonline.com/"+partnerId
    },
    cache: {
        cacheLocation: 'localStorage',
        }
}
export default auths