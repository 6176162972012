<template>
<div>
    <div class="layoutContainer">
        <sidebar />
        <div style="width:100%;" :style="{paddingLeft:paddingClass+'px'}">
            <navbar />
            <router-view style="height:100%" />
            <div style="padding-left:210px">
                <nav class="navbar fixed-bottom" style="color:white;background-color:#304156">
                <div class="container-fluid">
                    <div style="margin:0 auto;font-size:13px">
                        <div>Copyright © {{year}}. All rights reserved. Powered by CYBERNETIC SYSTEMS LLP.</div>
                    </div>
                </div>
            </nav>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import sidebar from '@/sidebar/newSidebar'
import navbar from '@/navbar/index'
import {
    bus
} from '@/eventbus/eventbus'
import footers from './footers'
export default {
    name: 'items',
    components: {
        sidebar,
        navbar,
        bus,
        footers
    },
    data() {
        return {
            toggleChild: false,
            paddingClass: 210,
            isCollapse: true,
            year:''
        }
    },
    created() {  
        let date= new Date()
        this.year = date.getFullYear()
        bus.$on('sidebarToggle', (data) => {
            console.log(data, 'data')
            if (data) {
                this.paddingClass = 64
            } else {
                this.paddingClass = 210
            }
        });
    },
    updated() {

    },
    methods: {

    }
}
</script>

<style scoped>
.layoutContainer {
    display: flex;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 209px;
    z-index: 1030;
}
</style>
