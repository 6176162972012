import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import ElementUI from 'element-ui';
import enLang from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import microsoftLoginAuth from './microsoftLoginAuth'

Vue.use(microsoftLoginAuth)
Vue.use(Cookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ElementUI, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    locale: enLang // 如果使用中文，无需设置，请删除
})
Vue.use(VueAxios, axios)
Vue.use(Vuex)

Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('formatDateYMDHMS', function(value) {
  if(value == '' || value == '0000-00-00 00:00:00' || value =='1970-01-01 00:00:00' || value==undefined)
  {
      return ''
  }
  const date = new Date(value) // 2020-06-21
  const longMonth = date.toLocaleString('en-us', { month: 'long' }) /* June */
  const hours=date.getHours() < 10 ? '0'+ date.getHours() :  date.getHours()
  const minute = date.getMinutes() < 10 ? '0'+ date.getMinutes() :  date.getMinutes()
  const seconds = date.getSeconds() < 10 ? '0'+ date.getSeconds() :  date.getSeconds()
  const result = date.getDate() + ' ' + longMonth + ' ' + date.getFullYear() + ' ' + hours +
      ':' + minute + ':' + seconds
  return result
      // return moment(String(value)).format('DD/MM/YYYY hh:mm')
})

Vue.filter('formatDateYMD', function(value) {
  if (!value || value== '0000-00-00') return ''
  const date = new Date(value) // 2020-06-21
  const longMonth = date.toLocaleString('en-us', { month: 'long' }) /* June */
  const result = date.getDate() + ' ' + longMonth + ' ' + date.getFullYear()
  return result
      // return moment(String(value)).format('DD/MM/YYYY hh:mm')
})

Vue.filter('formatDateYM', function(value) {
  if (!value) return ''
  const date = new Date(value) // 2020-06-21
  const longMonth = date.toLocaleString('en-us', { month: 'long' }) /* June */
  const result = longMonth + ' ' + date.getFullYear()
  return result
      // return moment(String(value)).format('DD/MM/YYYY hh:mm')
})

Vue.config.productionTip = false

Vue.prototype.$Cookies = Cookies
Vue.prototype.$microsoftAuth = microsoftLoginAuth
Vue.prototype.$localMsalInstance = {}
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

